import * as React from 'react';
import { useContext, useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName, StackName } from '../types';
import { EventScreen, EventsScreen, PostFilterScreen } from '../../screens';
import { isMobile } from '../../utils/responsive';
import { StackHeader } from '../../components';
import { NavigationContextStack } from '../../contexts/NavigationContext/NavigationContextStack';

const EventsStack = createStackNavigator();

export default function EventsStackNavigator({ navigation, route }) {
  const { t } = useTranslation();
  const { setChangedStack } = useContext(NavigationContextStack);

  const eventListener = isMobile ? 'focus' : 'blur';

  // Saving the current stack in a context
  useEffect(() => {
    return navigation.addListener(eventListener, () => {
      setChangedStack(StackName.EventsStack);
    });
  }, [navigation]);

  return (
    <EventsStack.Navigator
      screenOptions={{
        gestureEnabled: true, // Ensures the swipe gesture is enabled
        headerShown: false,
      }}
    >
      <EventsStack.Screen
        name={ScreenName.EventsScreen}
        component={EventsScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return (
                <StackHeader
                  navigation={navigation}
                  headerTitle={t('events:title')}
                  fromScreen={ScreenName.EventsScreen}
                />
              );
            },
            title: t('events:title'),
          };
        }}
      />

      <EventsStack.Screen
        name={ScreenName.EventScreen}
        component={EventScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} />;
            },
          };
        }}
      />
      <EventsStack.Screen
        name={ScreenName.PostFilterScreen}
        component={PostFilterScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} headerTitle={t('common:search')} />;
            },
          };
        }}
      />
    </EventsStack.Navigator>
  );
}
