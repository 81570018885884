import { NavigationProp, ParamListBase, RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';
import {
  GetAdminArticlesParams,
  GetAdminEventsParams,
  GetAdminExtremeEventsParams,
  ModuleKey,
} from '../client/interfaces';
import { ErrorType } from '../resources/interfaces';

export type NavigationParamList = {
  [ScreenName.HomeScreen]: undefined;
  [ScreenName.FavoritesScreen]: undefined;
  [ScreenName.PlatformsScreen]: undefined;
  [ScreenName.SettingsScreen]: undefined;
  [ScreenName.ArticlesScreen]: { isBookmarked?: boolean };
  [ScreenName.EventsScreen]: { isBookmarked?: boolean };
  [ScreenName.ProfileScreen]: undefined;
  [ScreenName.ArticleScreen]: { postId: number };
  [ScreenName.EventScreen]: { postId: number };
  [ScreenName.PostFilterScreen]: { fromScreen: ScreenName };
  [ScreenName.OnboardingScreen]: undefined;
  [ScreenName.AdminIndexScreen]: undefined;
  [ScreenName.AdminArticleListScreen]: GetAdminArticlesParams;
  [ScreenName.AdminArticleEditScreen]: { postId?: number };
  [ScreenName.AdminExtremeEventListScreen]: GetAdminExtremeEventsParams;
  [ScreenName.AdminEventListScreen]: GetAdminEventsParams;
  [ScreenName.AdminExtremeEventEditScreen]: { postId?: number };
  [ScreenName.AdminEventEditScreen]: { postId?: number };
  [StackName.ErrorStack]: { type?: ErrorType };
  [ScreenName.PersonalizationScreen]: undefined;
  [ScreenName.NotificationsScreen]: { fromScreen: ScreenName };
  [ScreenName.SplashScreen]: undefined;
  '*': { type?: ErrorType };
};

export enum Navigator {
  main = 'main',
  profile = 'profile',
  admin = 'admin',
}

export interface Screen {
  name: ScreenName;
  stackName?: StackName;
  component?: any;
  translation: string;
  navigator?: Navigator;
  icon?: any;
  moduleKey?: ModuleKey; // Ki-be kapcsolható backenden
}

export enum ScreenName {
  HomeScreen = 'home',
  EventsScreen = 'events',
  PlatformsScreen = 'platforms',
  ArticlesScreen = 'articles',
  FavoritesScreen = 'favorites',
  SettingsScreen = 'settings',
  AdminIndexScreen = 'admin',
  AdminArticleListScreen = 'adminArticles',
  AdminArticleEditScreen = `adminArticleEdit`,
  AdminExtremeEventListScreen = 'adminExtremeEvents',
  AdminExtremeEventEditScreen = `adminExtremeEventEdit`,
  AdminEventListScreen = `adminEvents`,
  AdminEventEditScreen = `adminEventEdit`,
  ProfileScreen = 'profile',
  OnboardingScreen = 'onboarding',
  ArticleScreen = 'article',
  EventScreen = 'event',
  ErrorScreen = 'error',
  PostFilterScreen = 'filter',
  NotFound = `*`,
  PersonalizationScreen = 'personalization',
  NotificationsScreen = 'notifications',
  SplashScreen = 'splash',
}

export enum StackName {
  HomeStack = 'HomeStack',
  ArticlesStack = 'ArticlesStack',
  EventsStack = 'EventsStack',
  PlatformsStack = 'PlatformsStack',
  AdminStack = 'AdminStack',
  SettingsStack = 'SettingsStack',
  FavoritesStack = 'FavoritesStack',
  OnboardingStack = 'OnboardingStack',
  ErrorStack = 'ErrorStack',
  NotificationsStack = 'NotificationsStack',
  ProfileStack = 'ProfileStack',
}

export type NotificationRedirectScreenName = Extract<
  ScreenName,
  ScreenName.ArticleScreen | ScreenName.EventScreen | ScreenName.HomeScreen
>;

export type TabNavigationScreenProps<
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList = keyof ParamList,
  NavigatorID extends string | undefined = undefined,
> = {
  navigation: NavigationProp<ParamList, RouteName, NavigatorID>;
  route: RouteProp<ParamList, RouteName>;
};

export type StackNavigationScreenProps<
  ParamList extends Record<string, object | undefined>,
  RouteName extends keyof ParamList = keyof ParamList,
> = {
  navigation: NativeStackNavigationProp<ParamList, RouteName>;
  route: RouteProp<ParamList, RouteName>;
};

export type TabNavigationOptions = {
  title?: string;
};

export type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};
