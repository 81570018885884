import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName } from '../types';
import {
  AdminArticleEditScreen,
  AdminArticleListScreen,
  AdminEventEditScreen,
  AdminEventListScreen,
  AdminExtremeEventEditScreen,
  AdminExtremeEventListScreen,
  AdminIndexScreen,
  ArticleScreen,
  EventScreen,
} from '../../screens';

const AdminStack = createStackNavigator();

const AdminStackNavigator = ({ navigation }) => {
  const { t } = useTranslation();
  return (
    <AdminStack.Navigator>
      <AdminStack.Screen
        name={ScreenName.AdminIndexScreen}
        component={AdminIndexScreen}
        options={() => {
          return {
            headerShown: false,
            title: t('navigation:admin_index'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminArticleListScreen}
        component={AdminArticleListScreen}
        options={() => {
          return {
            headerShown: false,
            title: t('navigation:admin_article_list'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.ArticleScreen}
        component={ArticleScreen}
        options={() => {
          return {
            headerShown: false,
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminEventListScreen}
        component={AdminEventListScreen}
        options={() => {
          return {
            headerShown: false,
            title: t('navigation:admin_event_list'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.EventScreen}
        component={EventScreen}
        options={() => {
          return {
            headerShown: false,
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminExtremeEventListScreen}
        component={AdminExtremeEventListScreen}
        options={() => {
          return {
            headerShown: false,
            title: t('navigation:admin_extreme_event_list'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminArticleEditScreen}
        component={AdminArticleEditScreen}
        options={() => {
          return {
            headerShown: false,
            title: t('navigation:admin_article_edit'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminEventEditScreen}
        component={AdminEventEditScreen}
        options={() => {
          return {
            headerShown: false,
            title: t('navigation:admin_event_edit'),
          };
        }}
      />
      <AdminStack.Screen
        name={ScreenName.AdminExtremeEventEditScreen}
        component={AdminExtremeEventEditScreen}
        options={() => {
          return {
            headerShown: false,
            title: t('navigation:admin_extreme_event_edit'),
          };
        }}
      />
    </AdminStack.Navigator>
  );
};

export default AdminStackNavigator;
