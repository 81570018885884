import React, { createContext, Dispatch, SetStateAction, useState, useMemo } from 'react';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';
import {
  Descriptor,
  NavigationProp,
  ParamListBase,
  RouteProp,
  TabNavigationState,
} from '@react-navigation/native';
import { NavigationParamList } from '../../navigation/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TabDescriptor = Record<string, Descriptor<any, any, any>>;

interface NavigationContextType {
  stackNavigation: NativeStackNavigationProp<NavigationParamList>;
  setStackNavigation: React.Dispatch<
    React.SetStateAction<NativeStackNavigationProp<NavigationParamList> | undefined>
  >;
  stackRoute: RouteProp<Record<string, object | undefined>>;
  setStackRoute: React.Dispatch<
    React.SetStateAction<RouteProp<Record<string, object | undefined>>>
  >;
  changedStack: string;
  setChangedStack: Dispatch<SetStateAction<string>>;
  mainTabProps?: {
    state: TabNavigationState<ParamListBase>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigation: any;
    descriptors: TabDescriptor;
  };
  setMainTabProps?: Dispatch<
    SetStateAction<{
      state: TabNavigationState<ParamListBase>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      navigation: any;
      descriptors: TabDescriptor;
    }>
  >;
}

const NavigationContextStack = createContext<NavigationContextType | undefined>(undefined);

export const StackProvider = ({ children }) => {
  const [stackNavigation, setStackNavigation] =
    useState<NativeStackNavigationProp<NavigationParamList>>();
  const [stackRoute, setStackRoute] = useState<RouteProp<Record<string, object | undefined>>>();
  const [changedStack, setChangedStack] = useState(null);
  const [mainTabProps, setMainTabProps] = useState(null);

  const navigationContextValue = useMemo(
    () => ({
      stackNavigation,
      setStackNavigation,
      stackRoute,
      setStackRoute,
      changedStack,
      setChangedStack,
      mainTabProps,
      setMainTabProps,
    }),
    [
      stackNavigation,
      setStackNavigation,
      stackRoute,
      setStackRoute,
      changedStack,
      setChangedStack,
      mainTabProps,
      setMainTabProps,
    ],
  );

  return (
    <NavigationContextStack.Provider value={navigationContextValue}>
      {children}
    </NavigationContextStack.Provider>
  );
};

export { NavigationContextStack };
