import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { FavoritesScreen } from '../../screens';
import { isMobile } from '../../utils/responsive';
import { StackHeader } from '../../components';
import { ScreenName } from '../types';

const FavoritesStack = createStackNavigator();

export default function FavoritesStackNavigator() {
  const { t } = useTranslation();
  return (
    <FavoritesStack.Navigator
      screenOptions={{
        gestureEnabled: true,
        headerShown: false,
      }}
    >
      <FavoritesStack.Screen
        name={ScreenName.FavoritesScreen}
        component={FavoritesScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return (
                <StackHeader navigation={navigation} headerTitle={t('navigation:favorites')} />
              );
            },
            title: t('navigation:favorites'),
          };
        }}
      />
    </FavoritesStack.Navigator>
  );
}
