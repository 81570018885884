import { ModuleKey } from '../client/interfaces';
import { isMobile } from '../utils/responsive';
import { SplashScreen } from '../screens';
import { Screen, ScreenName, StackName } from './types';
import {
  AdminStackNavigator,
  ArticlesStackNavigator,
  ErrorStackNavigator,
  EventsStackNavigator,
  FavoritesStackNavigator,
  HomeStackNavigator,
  NotificationsStackNavigator,
  OnboardingStackNavigator,
  PlatformsStackNavigator,
  ProfileStackNavigator,
  SettingsStackNavigator,
} from './stackNavigators';

const navigationConfig: Screen[] = [
  // Main stacks
  {
    component: HomeStackNavigator,
    name: ScreenName.HomeScreen,
    stackName: StackName.HomeStack,
    translation: 'navigation:home',
  },
  {
    component: ArticlesStackNavigator,
    name: ScreenName.ArticlesScreen,
    stackName: StackName.ArticlesStack,
    translation: 'navigation:articles',
    moduleKey: ModuleKey.feed,
  },
  {
    component: EventsStackNavigator,
    name: ScreenName.EventsScreen,
    stackName: StackName.EventsStack,
    translation: 'navigation:events',
    moduleKey: ModuleKey.event,
  },
  {
    component: PlatformsStackNavigator,
    name: ScreenName.PlatformsScreen,
    stackName: StackName.PlatformsStack,
    translation: 'navigation:platforms',
  },
  {
    component: AdminStackNavigator,
    name: ScreenName.AdminIndexScreen,
    stackName: StackName.AdminStack,
    translation: 'navigation:admin_index',
  },
  {
    component: SettingsStackNavigator,
    name: ScreenName.SettingsScreen,
    stackName: StackName.SettingsStack,
    translation: 'navigation:settings',
  },
  {
    component: FavoritesStackNavigator,
    name: ScreenName.FavoritesScreen,
    stackName: StackName.FavoritesStack,
    translation: 'navigation:favorites',
  },
  {
    component: OnboardingStackNavigator,
    name: ScreenName.OnboardingScreen,
    stackName: StackName.OnboardingStack,
    translation: 'navigation:onboarding',
  },
  {
    component: ErrorStackNavigator,
    name: ScreenName.ErrorScreen,
    stackName: StackName.ErrorStack,
    translation: 'navigation:error',
  },
  {
    component: SplashScreen,
    name: ScreenName.SplashScreen,
    translation: '',
  },
  ...(isMobile
    ? [
        {
          component: ProfileStackNavigator,
          name: ScreenName.ProfileScreen,
          stackName: StackName.ProfileStack,
          translation: 'navigation:profile',
        },
        {
          component: NotificationsStackNavigator,
          name: ScreenName.NotificationsScreen,
          stackName: StackName.NotificationsStack,
          translation: 'navigation:notifications',
        },
      ]
    : []),
];

export default navigationConfig;
