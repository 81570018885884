import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { EventProvider } from 'react-native-outside-press';
import { useContext, useEffect } from 'react';
import { useModules } from '../contexts/Module/ModuleContext';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import { isMobile } from '../utils/responsive';
import { NavigationContextStack } from '../contexts/NavigationContext/NavigationContextStack';
import navigationConfig from './navigationConfig';
import MainMenu from './MainMenu';
import ProfileMenu from './ProfileMenu';
import { ScreenName, StackName, TabNavigationEventMap, TabNavigationOptions } from './types';
import { ErrorStackNavigator, OnboardingStackNavigator } from './stackNavigators';

type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions;

const MainTabNavigation = ({ initialRouteName, children, screenOptions }: Props) => {
  const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  const { setMainTabProps } = useContext(NavigationContextStack);

  const menuProps = {
    state,
    navigation,
    descriptors,
  };

  useEffect(() => {
    setMainTabProps(menuProps);
  }, []);

  return (
    <NavigationContent>
      <EventProvider>
        <View style={[{ flex: 1 }]}>
          {state.routes.map((route, i) => {
            return (
              <View
                key={route.key}
                style={[StyleSheet.absoluteFill, { display: i === state.index ? 'flex' : 'none' }]}
              >
                {descriptors[route.key].render()}
              </View>
            );
          })}
        </View>
        {!state.routes[state.index].name.includes(StackName.OnboardingStack) && !isMobile && (
          <ProfileMenu {...menuProps} />
        )}
        {!state.routes[state.index].name.includes(StackName.OnboardingStack) && (
          <MainMenu {...menuProps} />
        )}
      </EventProvider>
    </NavigationContent>
  );
};

const bottomTabNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof MainTabNavigation
>(MainTabNavigation);

const Tab = bottomTabNavigator();

const TabNavigator = () => {
  const { t } = useTranslation();
  const { isModuleActive } = useModules();
  const { me, isAdmin } = useAuth();

  return (
    <Tab.Navigator backBehavior={'order'}>
      {me?.isOnboarded ? (
        navigationConfig.map((screen) => {
          let isActive = true;

          // Check if module is activated
          if (screen?.moduleKey && !isModuleActive(screen.moduleKey)) {
            isActive = false;
          }

          // Check if user is admin
          if (screen.name === ScreenName.AdminIndexScreen && (!isAdmin || isMobile)) {
            isActive = false;
          }

          if (isActive) {
            return (
              <Tab.Screen
                key={screen.name}
                name={screen.stackName || screen.name}
                component={screen.component}
                options={{
                  title: t(screen.translation),
                }}
              />
            );
          }
        })
      ) : !me ? (
        <Tab.Screen
          key={ScreenName.ErrorScreen}
          name={StackName.ErrorStack}
          component={ErrorStackNavigator}
        />
      ) : (
        <Tab.Screen
          key={ScreenName.OnboardingScreen}
          name={StackName.OnboardingStack}
          component={OnboardingStackNavigator}
          options={{
            title: t('navigation:onboarding'),
          }}
        />
      )}
    </Tab.Navigator>
  );
};
export default TabNavigator;
