import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { FadeInOut } from '../index';
import { cln } from '../../utils/classnames';
import Icon from '../Icon';
import svgIcons from '../../assets';

interface Props {
  fontsLoaded?: boolean;
}

const ToastMessage: React.FC<Props> = ({ fontsLoaded = true }) => {
  const { message, type, resetMessage } = useMessage();

  return (
    <View
      className={cln(message ? 'z-[10000] self-center top-16 md:top-5' : 'hidden')}
      style={{ position: 'absolute' }}
    >
      <FadeInOut duration={500} timeout={message ? 9000 : 500}>
        {!!message && (
          <Pressable
            className={cln(
              'min-h-[40px] py-2 max-w-[400px] items-center justify-center',
              type === 'error' ? 'bg-danger-500' : 'bg-secondary-500',
            )}
            onPress={resetMessage}
          >
            <View className="items-center justify-center flex-row px-[32px]">
              <Icon
                icon={type === 'error' ? svgIcons.alertIconLight : svgIcons.checkmarkIconDark}
                mobileSize={{ width: 20, height: 20 }}
                webSize={{ width: 20, height: 20 }}
              />
              <Text
                className={cln(
                  'ml-3 text-notificationMessage font-[sans-600]',
                  type === 'error' ? 'text-neutral-50' : 'text-neutral-930',
                )}
              >
                {message}
              </Text>
            </View>
          </Pressable>
        )}
      </FadeInOut>
    </View>
  );
};

export default ToastMessage;
