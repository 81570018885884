import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName } from '../types';
import { OnboardingScreen } from '../../screens';
import { StackHeader } from '../../components';

const OnboardingStack = createStackNavigator();

export default function OnboardingStackNavigator() {
  const { t } = useTranslation();
  return (
    <OnboardingStack.Navigator
      screenOptions={{
        gestureEnabled: true, // Ensures the swipe gesture is enabled
        headerShown: false,
      }}
    >
      <OnboardingStack.Screen
        name={ScreenName.OnboardingScreen}
        component={OnboardingScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            header: () => {
              return (
                <StackHeader navigation={navigation} headerTitle={t('navigation:onboarding')} />
              );
            },
            title: t('navigation:onboarding'),
          };
        }}
      />
    </OnboardingStack.Navigator>
  );
}
