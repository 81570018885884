import './utils/gesture-handler';
import { NativeWindStyleSheet, useColorScheme } from 'nativewind';
import { StatusBar } from 'expo-status-bar';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import './assets/locales/i18n';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import React, { useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import ErrorBoundary from 'react-native-error-boundary';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import MainNavigator from './navigation/MainNavigator';
import linkingConfig from './navigation/linkingConfig';
import { ErrorScreen, SplashScreen } from './screens';
import { MessageProvider } from './contexts/Messages/MessageContext';
import { AppDataLoader, ToastMessage } from './components';
import { TagProvider } from './contexts/Tag/TagContext';
import { ModuleProvider } from './contexts/Module/ModuleContext';
import { AuthProvider } from './contexts/AuthContext/AuthContext';
import AuthGuard from './guards/AuthGuard';
import { NotificationProvider } from './contexts/Notification/NotificationContext';
import { PushNotificationProvider } from './contexts/PushNotification/PushNotificationContext';
import { AdminProvider } from './contexts/Admin/AdminContext';
import { ExtremeEventProvider } from './contexts/ExtremeEvent/ExtremeEventContext';
import { ArticlesProvider } from './contexts/ArticlesParams/ArticlesParamsContext';
import { EventsProvider } from './contexts/EventsParams/EventsParamsContext';
import { StackProvider } from './contexts/NavigationContext/NavigationContextStack';
import { ErrorType } from './resources/interfaces';

NativeWindStyleSheet.setOutput({
  default: 'native',
});

export default function App() {
  const { colorScheme } = useColorScheme();

  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);

  const onAppLoadingReady = () => {
    setShowSplashScreen(false);
  };

  return (
    <SafeAreaProvider>
      <ErrorBoundary
        FallbackComponent={() => (
          <ErrorScreen errorStackRoute={{ type: ErrorType.internal_server_error }} />
        )}
      >
        <GestureHandlerRootView
          className="flex-1"
          style={{
            backgroundColor: colorScheme === 'light' ? '#f6f6f6' : '#101010',
          }}
        >
          <AuthProvider>
            <AuthGuard onLogin={() => setShowSplashScreen(true)}>
              <MessageProvider>
                <ModuleProvider>
                  <TagProvider>
                    <NotificationProvider>
                      <ExtremeEventProvider>
                        <ArticlesProvider>
                          <EventsProvider>
                            <AdminProvider>
                              <StatusBar
                                backgroundColor={colorScheme === 'light' ? '#f6f6f6' : '#0b0b0b'}
                                style={colorScheme === 'light' ? 'dark' : 'light'}
                              />
                              {showSplashScreen ? (
                                <>
                                  <ToastMessage fontsLoaded={false} />
                                  <SplashScreen />
                                  <AppDataLoader onAppLoadingReady={onAppLoadingReady} />
                                </>
                              ) : (
                                <>
                                  <ToastMessage />
                                  <NavigationContainer
                                    linking={linkingConfig}
                                    theme={{
                                      ...DefaultTheme,
                                      colors: {
                                        ...DefaultTheme.colors,
                                        background: colorScheme === 'light' ? '#f6f6f6' : '#0b0b0b',
                                      },
                                    }}
                                  >
                                    <PushNotificationProvider>
                                      <ActionSheetProvider>
                                        <StackProvider>
                                          <MainNavigator />
                                        </StackProvider>
                                      </ActionSheetProvider>
                                    </PushNotificationProvider>
                                  </NavigationContainer>
                                </>
                              )}
                            </AdminProvider>
                          </EventsProvider>
                        </ArticlesProvider>
                      </ExtremeEventProvider>
                    </NotificationProvider>
                  </TagProvider>
                </ModuleProvider>
              </MessageProvider>
            </AuthGuard>
          </AuthProvider>
        </GestureHandlerRootView>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
}
