import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName } from '../types';
import { ErrorScreen } from '../../screens';

const ErrorStack = createStackNavigator();

export default function ErrorStackNavigator({ route }) {
  const { t } = useTranslation();
  return (
    <ErrorStack.Navigator
      screenOptions={{
        gestureEnabled: true, // Ensures the swipe gesture is enabled
        headerShown: false,
      }}
    >
      <ErrorStack.Screen
        name={ScreenName.ErrorScreen}
        options={() => {
          return {
            gestureEnabled: true,
            headerShown: false,
            title: t('navigation:error'),
          };
        }}
      >
        {(props) => <ErrorScreen errorStackRoute={route?.params} />}
      </ErrorStack.Screen>
    </ErrorStack.Navigator>
  );
}
