import {
  getStateFromPath as defaultGetStateFromPath,
  LinkingOptions,
} from '@react-navigation/native';
import { ErrorType } from '../resources/interfaces';
import { ScreenName, StackName } from './types';

const linkingConfig: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: ['ulp.mome://', 'https://ulp.mome.hu', 'https://*.ulp.mome.hu'], // TODO: Expo universal links
  config: {
    screens: {
      [StackName.OnboardingStack]: {
        initialRouteName: 'onboarding',
        screens: {
          [ScreenName.OnboardingScreen]: 'onboarding',
        },
      },
      [StackName.ErrorStack]: {
        initialRouteName: 'error',
        screens: {
          [ScreenName.ErrorScreen]: 'error',
        },
      },
      [StackName.HomeStack]: {
        initialRouteName: 'home',
        screens: {
          [ScreenName.HomeScreen]: 'home',
          [ScreenName.ArticleScreen]: 'home/article',
          [ScreenName.EventScreen]: 'home/event',
        },
      },
      [StackName.ArticlesStack]: {
        initialRouteName: 'articles',
        screens: {
          [ScreenName.ArticlesScreen]: 'articles',
          [ScreenName.ArticleScreen]: 'articles/article',
          [ScreenName.PostFilterScreen]: 'articles/filter',
        },
      },
      [StackName.EventsStack]: {
        initialRouteName: 'events',
        screens: {
          [ScreenName.EventsScreen]: 'events',
          [ScreenName.EventScreen]: 'events/event',
          [ScreenName.PostFilterScreen]: 'events/filter',
        },
      },
      [StackName.PlatformsStack]: {
        initialRouteName: 'platforms',
        screens: {
          [ScreenName.PlatformsScreen]: 'platforms',
        },
      },
      [StackName.FavoritesStack]: {
        initialRouteName: 'favorites',
        screens: {
          [ScreenName.FavoritesScreen]: 'favorites',
        },
      },
      [StackName.ProfileStack]: {
        initialRouteName: 'profile',
        screens: {
          [ScreenName.ProfileScreen]: 'profile',
          [ScreenName.SettingsScreen]: 'profile/settings',
          [ScreenName.FavoritesScreen]: 'profile/favorites',
          [ScreenName.PersonalizationScreen]: 'profile/settings/personalization',
        },
      },
      [StackName.NotificationsStack]: {
        initialRouteName: 'notifications',
        screens: {
          [ScreenName.NotificationsScreen]: 'notifications',
        },
      },
      [StackName.SettingsStack]: {
        initialRouteName: 'settings',
        screens: {
          [ScreenName.SettingsScreen]: 'settings',
          [ScreenName.PersonalizationScreen]: 'settings/personalization',
        },
      },
      [StackName.AdminStack]: {
        initialRouteName: 'admin',
        screens: {
          [ScreenName.AdminIndexScreen]: 'admin',
          [ScreenName.AdminArticleListScreen]: 'admin/articles',
          [ScreenName.ArticleScreen]: 'admin/articles/article',
          [ScreenName.AdminArticleEditScreen]: 'admin/articles/article/edit',
          [ScreenName.AdminEventListScreen]: 'admin/events',
          [ScreenName.EventScreen]: 'admin/events/event',
          [ScreenName.AdminEventEditScreen]: 'admin/events/event/edit',
          [ScreenName.AdminExtremeEventListScreen]: 'admin/extreme-events',
          [ScreenName.AdminExtremeEventEditScreen]: 'admin/extreme-events/extreme-event/edit',
        },
      },
    },
  },
  getStateFromPath: (path, options) => {
    const state = defaultGetStateFromPath(path, options);
    if (path === '/' || path === '/home') {
      return {
        routes: [{ name: ScreenName.HomeScreen }],
      };
    } else if (!state) {
      return {
        routes: [{ name: StackName.ErrorStack, params: { type: ErrorType.not_found } }],
      };
    }

    return state;
  },
};

export default linkingConfig;
