import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName } from '../types';
import { PlatformsScreen } from '../../screens';
import { isMobile } from '../../utils/responsive';
import { StackHeader } from '../../components';

const PlatformsStack = createStackNavigator();

export default function PlatformsStackNavigator() {
  const { t } = useTranslation();
  return (
    <PlatformsStack.Navigator
      screenOptions={{
        gestureEnabled: true, // Ensures the swipe gesture is enabled
        headerShown: false,
      }}
    >
      <PlatformsStack.Screen
        name={ScreenName.PlatformsScreen}
        component={PlatformsScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return (
                <StackHeader navigation={navigation} headerTitle={t('navigation:platforms')} />
              );
            },
            title: t('navigation:platforms'),
          };
        }}
      />
    </PlatformsStack.Navigator>
  );
}
