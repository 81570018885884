import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useContext } from 'react';
import { ScreenName } from '../types';
import { ArticleScreen, EventScreen, HomeScreen } from '../../screens';
import { isMobile } from '../../utils/responsive';
import ProfileMenu from '../ProfileMenu';
import { StackHeader } from '../../components';
import { NavigationContextStack } from '../../contexts/NavigationContext/NavigationContextStack';

const HomeStack = createStackNavigator();

export default function HomeStackNavigator() {
  const { t } = useTranslation();
  const { mainTabProps } = useContext(NavigationContextStack);

  return (
    <HomeStack.Navigator
      screenOptions={{
        gestureEnabled: true,
        headerShown: false,
      }}
    >
      <HomeStack.Screen
        name={ScreenName.HomeScreen}
        component={HomeScreen}
        options={() => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <ProfileMenu {...mainTabProps} />;
            },
            title: t('navigation:home'),
          };
        }}
      />
      <HomeStack.Screen
        name={ScreenName.ArticleScreen}
        component={ArticleScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} />;
            },
          };
        }}
      />
      <HomeStack.Screen
        name={ScreenName.EventScreen}
        component={EventScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} />;
            },
          };
        }}
      />
    </HomeStack.Navigator>
  );
}
