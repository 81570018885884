import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import dayjs from 'dayjs';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Icon, Table } from '../index';
import { AdminPostRead, GetAdminArticlesParams, PersonaType } from '../../client/interfaces';
import { useMessage } from '../../contexts/Messages/MessageContext';
import svgIcons from '../../assets';
import { useTags } from '../../contexts/Tag/TagContext';
import Modal from '../Modal';
import TableCell from '../TableCell';
import client from '../../client/client';
import { NavigationParamList, ScreenName } from '../../navigation/types';

interface Props {
  rows;
  paramSetter: React.Dispatch<React.SetStateAction<GetAdminArticlesParams>>;
  params: GetAdminArticlesParams;
  refresh: () => Promise<void>;
  isLoading?: boolean;
}

const EventTable: React.FC<Props> = ({ rows, paramSetter, params, refresh, isLoading = false }) => {
  const { t } = useTranslation();
  const navigation =
    useNavigation<NavigationProp<NavigationParamList, ScreenName.AdminEventEditScreen>>();
  const { setMessage } = useMessage();
  const { tags } = useTags();

  const [eventToDelete, setEventToDelete] = useState<AdminPostRead>();

  const deleteEvent = async (event: AdminPostRead) => {
    try {
      await client.deletePost(event.id);
      setMessage({
        message: t('success_messages:deleted'),
        type: 'success',
        translateMessage: false,
      });
      refresh();
    } catch (e) {
      console.error(e);
      setMessage({ message: e.message, type: 'error' });
    }
  };

  return (
    <View className="z-[10]">
      <Table
        paramSetter={paramSetter}
        params={params}
        isLoading={isLoading}
        keyFieldName="id"
        columns={[
          {
            key: 'title',
            label: t('admin:table_columns:events:title'),
            sortable: true,
            widthPercentage: 18,
            templateFunction: (row) => <TableCell text={row.titleTranslation} />,
          },
          {
            key: 'tags',

            label: t('admin:table_columns:common.tags'),
            relationTransformer: (row) =>
              row.tags.map((rowTag) => tags.find((tag) => tag.id === rowTag.id)?.translationValue),
          },
          {
            key: 'lastEditedBy',
            label: t('admin:table_columns:common.last_edited_by'),
            widthPercentage: 12,
          },
          {
            key: 'status',
            label: t('admin:table_columns:common.status'),
            widthPercentage: 12,
          },
          {
            key: 'created_at_utc',
            widthPercentage: 12,
            label: t('admin:table_columns:common.created_at'),
            sortable: true,
            templateFunction: (row) => (
              <TableCell
                text={dayjs(row.createdAtUtc).format('YYYY. MM. DD.')}
                key={`created_at_utc-${row.id}`}
              />
            ),
          },
          {
            key: 'start_date',
            widthPercentage: 15,
            label: t('admin:table_columns:common:event_starting'),
            sortable: true,
            templateFunction: (row) => (
              <TableCell
                text={dayjs(row.event.startDate).format('YYYY. MM. DD.')}
                key={`start_date-${row.id}`}
              />
            ),
          },
          {
            key: 'personas',
            label: t('admin:table_columns:common.personas'),
            relationTransformer: (row) => {
              let relations = [t('personas:all')];

              if (row.personas.length < Object.keys(PersonaType).length) {
                relations = row.personas.map((rowPersona) =>
                  t(`personas:${rowPersona.key}`)?.toLowerCase(),
                );
              }

              return relations;
            },
          },
          {
            key: 'actions',
            label: '',
            widthPercentage: 10,
            templateFunction: (row) => (
              <TableCell key={`actions-${row.id}`} classNames="flex-row justify-end">
                <Pressable
                  onPress={() => {
                    navigation.navigate(ScreenName.AdminEventEditScreen, { postId: row.id });
                  }}
                  className="items-center w-[24px] mr-4"
                >
                  <View className="cursor-pointer">
                    <Icon
                      icon={svgIcons.editIcon}
                      mobileSize={{ width: 24, height: 24 }}
                      webSize={{ width: 24, height: 24 }}
                    />
                  </View>
                </Pressable>
                <Pressable
                  onPress={() => {
                    navigation.navigate(ScreenName.EventScreen, { postId: row.id });
                  }}
                  className="items-center w-[24px] mr-4"
                >
                  <View className="cursor-pointer">
                    <Icon
                      icon={svgIcons.eyeIcon}
                      mobileSize={{ width: 24, height: 24 }}
                      webSize={{ width: 24, height: 24 }}
                    />
                  </View>
                </Pressable>
                {
                  <Pressable
                    onPress={() => {
                      setEventToDelete(row);
                    }}
                    className="items-center w-[24px]"
                  >
                    <View className="cursor-pointer">
                      <Icon
                        icon={svgIcons.deleteIcon}
                        mobileSize={{ width: 24, height: 24 }}
                        webSize={{ width: 24, height: 24 }}
                      />
                    </View>
                  </Pressable>
                }
              </TableCell>
            ),
          },
        ]}
        rows={rows}
      />
      <Modal
        showModal={!!eventToDelete}
        title={t('admin:event_list:sure_to_delete')}
        callback={() => {
          deleteEvent(eventToDelete);
          setEventToDelete(null);
        }}
        closeModal={() => {
          setEventToDelete(null);
        }}
        yesText={t('admin:event_list:yes_delete')}
        cancelText={t('admin:event_list:no')}
      />
    </View>
  );
};
export default EventTable;
