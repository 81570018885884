import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { NotificationsScreen } from '../../screens';
import { isMobile } from '../../utils/responsive';
import { StackHeader } from '../../components';
import { ScreenName } from '../types';

const NotificationsStack = createStackNavigator();

export default function NotificationsStackNavigator() {
  const { t } = useTranslation();
  return (
    <NotificationsStack.Navigator
      screenOptions={{
        gestureEnabled: true,
        headerShown: false,
      }}
    >
      <NotificationsStack.Screen
        name={ScreenName.NotificationsScreen}
        component={NotificationsScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return (
                <StackHeader navigation={navigation} headerTitle={t('navigation:notifications')} />
              );
            },
          };
        }}
      />
    </NotificationsStack.Navigator>
  );
}
